export const INSIGHTS_PAGE_TAKE_LIMIT = 20;

export const INSIGHTS_ALLOWED_FREE_ADS = 2;

export const INSIGHTS_STYLES: Partial<
  Record<number, { border: string; checkbox: string; stroke: string }>
> = {
  0: {
    border: 'border-purple-400',
    stroke: 'stroke-purple-400',
    checkbox: 'bg-purple-400',
  },
  1: {
    border: 'border-pink-400',
    stroke: 'stroke-pink-400',
    checkbox: 'bg-pink-400',
  },
  2: {
    border: 'border-orange-400',
    stroke: 'stroke-orange-400',
    checkbox: 'bg-orange-400',
  },
  3: {
    border: 'border-light-blue-400',
    stroke: 'stroke-light-blue-400',
    checkbox: 'bg-light-blue-400',
  },
  4: {
    border: 'border-green-400',
    stroke: 'stroke-green-400',
    checkbox: 'bg-green-400',
  },
};
