import { FC, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { AriaMenu } from 'src/components/AriaMenu/AriaMenu';
import { AriaButton } from 'src/components/Button/Button';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import { FacebookAuthState } from 'src/utils/useFacebook';
import useFeatureFlag from 'src/utils/useFeatureFlag';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';
import AriaModal from '../AriaModal/AriaModal';
import { Badge } from '../Badge';
import { AriaBaseButton } from '../Button/BaseButton';
import Avatar from '../Misc/Avatar';
import { Tooltip } from '../Tooltip/Tooltip';
import { SquareLoaders } from '../SquareLoaders';
import { Alert } from '../Alert';

type Props = {
  facebookAuthState: FacebookAuthState;
  onClose: () => void;
};

export const FacebookAdAccountSelectModal: FC<Props> = ({
  facebookAuthState,
  onClose,
}) => {
  const { LL } = useI18nContext();
  const { recordEvent } = useNewAnalyticsEvent();
  const user = useUserAndOrganisation();

  const existingFacebookIntegrations =
    user.data?.organisation.facebookIntegrations;

  const [selectedAdAccountIds, setSelectedAdAccountIds] = useState<string[]>(
    []
  );

  const queryClient = useQueryClient();

  const useRoles = useFeatureFlag('FACEBOOK_CONNECT_USE_ROLES_ENABLED');

  const updateFacebookIntegration =
    trpc.integrations.updateFacebookIntegration.useMutation({
      onSuccess: (result) => {
        void queryClient.invalidateQueries(
          getQueryKey(trpc.integrations.getIntegrations, undefined, 'query')
        );

        void queryClient.invalidateQueries(
          getQueryKey(
            trpc.insights.getManyFacebookAdAccounts,
            undefined,
            'query'
          )
        );

        void queryClient.invalidateQueries(
          getQueryKey(trpc.insights.getFacebookAdAccount, undefined, 'query')
        );

        const connections = Object.entries(result).reduce<{
          success: { adAccountId: string; name: string }[];
          fail: { adAccountId: string; name: string; reason: string }[];
        }>(
          (acc, [key, value]) => {
            if (value.status === 'success') {
              acc.success.push({ adAccountId: key, name: value.name });
            }

            if (value.status === 'failure') {
              acc.fail.push({
                adAccountId: key,
                name: value.name,
                reason: value.reason,
              });
            }

            return acc;
          },
          { success: [], fail: [] }
        );

        if (connections.success.length) {
          const details = connections.success
            .map((connection) => {
              void recordEvent({
                action: 'Connected',
                target: 'Insights Account',
                metadata: {
                  accountId: connection.adAccountId,
                  name: connection.name,
                },
              });

              return connection.name;
            })
            .join(', ');

          toast.success(
            LL.integrations.facebook.connectFacebook.success({ details }),
            {
              className: 'toast-success',
              autoClose: 7000,
            }
          );
        }

        if (connections.fail.length) {
          connections.fail.forEach((connection) => {
            const details = `${connection.name} (${connection.adAccountId}): ${connection.reason}`;
            toast.info(
              LL.integrations.facebook.connectFacebook.fail({
                details,
              }),
              { className: 'toast-danger', autoClose: 7000 }
            );
          });
        }

        onClose();
      },
      onError: (e) => {
        toast.error(
          LL.integrations.facebook.connectFacebook.error({
            error: 'message' in e ? e.message : 'Unknown error',
          }),
          { className: 'toast-danger' }
        );
      },
    });

  useEffect(() => {
    if (facebookAuthState.status === 'success') {
      void recordEvent({
        action: 'Post-FB Auth - Accounts Listed',
        target: 'Insights Account',
        metadata: {
          accounts: facebookAuthState.adAccounts
            .map((adAccount) => `${adAccount.accountId}|${adAccount.name}`)
            .join(', '),
        },
      });
    }
  }, [facebookAuthState, recordEvent]);

  return (
    <AriaModal
      show={facebookAuthState.status !== 'init'}
      onClose={onClose}
      title="Select Facebook Ad Accounts"
      className="!max-w-xl"
      footer={
        facebookAuthState.status === 'success' && (
          <div className="flex flex-row justify-between gap-4">
            <AriaButton
              className="shrink-0 grow"
              isDisabled={updateFacebookIntegration.isLoading}
              variant="secondary"
              onPress={onClose}
            >
              {LL.cancel()}
            </AriaButton>
            <AriaButton
              className="shrink-0 grow"
              loading={updateFacebookIntegration.isLoading}
              onPress={() => {
                const selectedAdAccounts = facebookAuthState.adAccounts.filter(
                  (x) => selectedAdAccountIds.includes(x.accountId)
                );

                updateFacebookIntegration.mutate({
                  fbUserID: facebookAuthState.fbUserID,
                  accessToken: facebookAuthState.accessToken,
                  adAccounts: selectedAdAccounts,
                });

                void recordEvent({
                  action: 'Post-FB Auth - Accounts Connected',
                  target: 'Insights Account',
                  metadata: {
                    accounts: facebookAuthState.adAccounts
                      .map(
                        (adAccount) =>
                          `${adAccount.accountId}|${adAccount.name}`
                      )
                      .join(', '),
                  },
                });
              }}
              isDisabled={selectedAdAccountIds.length === 0}
            >
              {LL.confirm()}
            </AriaButton>
          </div>
        )
      }
    >
      {facebookAuthState.status === 'error' && (
        <Alert className="w-full" type="error">
          <p className="text-xs font-normal">
            {LL.errors.genericWithDetail({
              detail: facebookAuthState.error.message ?? 'Unknown error',
            })}
          </p>
        </Alert>
      )}
      {facebookAuthState.status === 'pending' && (
        <div className="flex flex-col gap-3">
          <SquareLoaders className="h-10" amount={5} />
        </div>
      )}
      {facebookAuthState.status === 'success' && (
        <div className="flex flex-col gap-2">
          <p className="text-xs font-normal text-primary">
            {LL.insights.accounts.accountSelect.description()}
          </p>

          <h2 className="text-lg font-semibold text-primary">
            {LL.insights.accounts.accountSelect.availableTitle()}
            <span className="pl-2 text-xs text-primary/30">
              ({selectedAdAccountIds.length}/
              {facebookAuthState.adAccounts.length})
            </span>
          </h2>

          <AriaMenu.List
            items={facebookAuthState.adAccounts}
            onSelectionChange={(set) => {
              setSelectedAdAccountIds(Array.from(set as Set<string>));
            }}
            selectionMode="multiple"
            selectedKeys={selectedAdAccountIds}
            className="flex max-h-96 flex-col justify-between gap-3 overflow-auto"
          >
            {({ accountId, name, profilePicURL, role }) => {
              const existingAdAccount = existingFacebookIntegrations?.find(
                (integration) => integration.adAccountId === accountId
              );
              const exists = existingAdAccount != null;
              const enabled = existingAdAccount?.status === 'enabled' ?? true;
              const disabled =
                existingAdAccount?.status === 'disabled' ?? false;

              return (
                <AriaMenu.Item
                  id={accountId}
                  key={accountId}
                  // item box
                  className="font-primary h-auto cursor-pointer rounded-md border border-solid border-purple-300 p-10"
                  // checkbox
                  checkboxClassName={classNames(
                    'rounded-md border border-solid border-purple-300 text-white',
                    'group-selected:border-purple-800 group-selected:bg-purple-800',
                    'group-disabled:text-pink group-disabled:cursor-not-allowed group-disabled:border-gray-300 group-disabled:bg-gray-300'
                  )}
                  // text
                  contentClassName="flex w-full flex-row justify-between gap-2"
                  showCheckbox={true}
                  isDisabled={!!useRoles && role !== 'ADMIN'}
                >
                  <div className="flex flex-row items-center gap-2">
                    <Avatar
                      initial={name.charAt(0)}
                      src={profilePicURL}
                      className={classNames('h-6 w-6 shrink-0')}
                    />
                    <span className="font-primary text-xs font-semibold">
                      {name}
                    </span>
                  </div>
                  <div className="flex flex-row gap-2">
                    {!exists && (
                      <Tooltip>
                        <AriaBaseButton className="p-0">
                          <Badge color="primary">
                            {LL.insights.accounts.accountSelect.status.notAdded.ctaText()}
                          </Badge>
                        </AriaBaseButton>
                        <span>
                          {LL.insights.accounts.accountSelect.status.notAdded.tooltipText()}
                          <br />
                          {LL.insights.accounts.accountSelect.status.notAdded.tooltipAction()}
                        </span>
                      </Tooltip>
                    )}

                    {exists && enabled && (
                      <Tooltip>
                        <AriaBaseButton className="p-0">
                          <Badge color="success">
                            {LL.insights.accounts.accountSelect.status.connected.ctaText()}
                          </Badge>
                        </AriaBaseButton>
                        <span>
                          {LL.insights.accounts.accountSelect.status.connected.tooltipText()}
                          <br />
                          {LL.insights.accounts.accountSelect.status.connected.tooltipAction()}
                        </span>
                      </Tooltip>
                    )}

                    {exists && disabled && (
                      <Tooltip>
                        <AriaBaseButton className="p-0">
                          <Badge color="danger">
                            {LL.insights.accounts.accountSelect.status.reConnectionRequired.ctaText()}
                          </Badge>
                        </AriaBaseButton>
                        <span>
                          {LL.insights.accounts.accountSelect.status.reConnectionRequired.tooltipText()}
                          <br />
                          {LL.insights.accounts.accountSelect.status.reConnectionRequired.tooltipAction()}
                        </span>
                      </Tooltip>
                    )}

                    {!!useRoles && role !== 'ADMIN' && (
                      <Tooltip>
                        <AriaBaseButton className="p-0">
                          <Badge color="warning">
                            {LL.insights.accounts.accountSelect.status.noAdminAccess.ctaText()}
                          </Badge>
                        </AriaBaseButton>
                        <span>
                          {LL.insights.accounts.accountSelect.status.noAdminAccess.tooltipText()}
                          <br />
                          {LL.insights.accounts.accountSelect.status.noAdminAccess.tooltipAction()}
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </AriaMenu.Item>
              );
            }}
          </AriaMenu.List>
        </div>
      )}
    </AriaModal>
  );
};
