import { captureException } from '@sentry/react';
import { getFeatureFlagValue } from 'src/utils/getFeatureFlagValue';
import StatusResponse = facebook.StatusResponse;

/** @see {@link https://developers.facebook.com/docs/graph-api/guides/error-handling/} */
type FBError = {
  message: string;
  type: string;
  code: number;
  error_subcode: number;
  error_user_title: string;
  error_user_msg: string;
  fbtrace_id: string;
};

/** @see {@link https://developers.facebook.com/docs/graph-api/results/} */
type FBPaging = {
  cursors: {
    before: string;
    after: string;
  };
  previous: string;
  next: string;
};

type FBResponse<T> = {
  data?: T;
  paging?: FBPaging;
  error?: FBError;
};

export const init = () => {
  window.fbAsyncInit = function () {
    FB.init({
      appId: getFeatureFlagValue('FACEBOOK_APP_ID'),
      xfbml: true,
      version: getFeatureFlagValue('FACEBOOK_API_VERSION'),
    });
  };
};

type LoginResponse = {
  fbUserID: string;
  accessToken: string;
  expiresAt: number;
};

export const login = (): Promise<LoginResponse> => {
  try {
    const promise = new Promise<LoginResponse>((resolve, reject) => {
      FB.login(
        (response: StatusResponse) => {
          switch (response.status) {
            case 'connected':
              break;
            case 'authorization_expired':
              reject(new Error('Error logging in, authorization has expired'));
              return;
            case 'not_authorized':
              reject(new Error('Error logging in, user is not authorized'));
              return;
            case 'unknown':
              reject(new Error('Error logging in, unknown status received'));
              return;
            default:
              reject(new Error('Error logging in, user is not connected'));
              return;
          }

          const {
            userID: fbUserID,
            accessToken,
            data_access_expiration_time: expiresAt,
          } = response.authResponse;

          if (accessToken == null) {
            reject(new Error('No access token received'));
            return;
          }

          if (expiresAt == null) {
            reject(new Error('No expiry for token received'));
            return;
          }

          resolve({
            fbUserID,
            accessToken,
            expiresAt,
          });
        },
        {
          config_id: getFeatureFlagValue('FACEBOOK_APP_LOGIN_CONFIG_ID'),
        }
      );
    });

    return promise;
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('facebook login');
      return scope;
    });
    throw e;
  }
};

type User = {
  id: string;
  name: string;
  email: string;
};

export type GetUserResponse = User;

/** gets the 'user' for an id */
export const getUser = ({
  userId = 'me',
}: {
  userId: string;
}): Promise<GetUserResponse> => {
  const params: URLSearchParams = new URLSearchParams();
  params.append('fields', 'id,name,email');
  try {
    const promise = new Promise<GetUserResponse>((resolve) => {
      FB.api(
        `/${userId}?${params.toString()}`,
        async (response: GetUserResponse) => {
          resolve(response);
        }
      );
    });
    return promise;
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('facebook/{userId}');
      scope.setExtras({ userId, params: params.toString() });
      return scope;
    });
    throw e;
  }
};

type AdAccountUser = {
  id: string;
  name: string;
  tasks: string[];
};

type AdAccount = {
  id: string;
  name: string;
  users: FBResponse<AdAccountUser[]>;
  owner: string;
};

export type GetAdAccountsResponse = FBResponse<
  Pick<AdAccount, 'id' | 'name' | 'owner'>[]
>;

/** gets the 'ad accounts' for a user that they have access to */
const getAdAccounts = ({
  userId = 'me',
  limit = 25,
  after = undefined,
}: {
  userId: string;
  limit?: number;
  after?: string;
}): Promise<GetAdAccountsResponse> => {
  const params: URLSearchParams = new URLSearchParams();

  params.append('fields', 'id,name,owner');
  params.append('limit', `${limit}`);

  if (after) {
    params.append('after', `${after}`);
  }

  try {
    const promise = new Promise<GetAdAccountsResponse>((resolve, reject) => {
      FB.api(
        `/${userId}/adaccounts?${params.toString()}`,
        async (response: GetAdAccountsResponse) => {
          if (response.error) {
            reject(
              new Error(response.error.message ?? 'Error getting ad accounts')
            );
            return;
          }

          if (response.data == null) {
            reject(new Error('No data received for ad accounts'));
            return;
          }

          resolve(response);
        }
      );
    });

    return promise;
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('facebook/{userId}/adaccounts');
      scope.setExtras({ userId, params: params.toString() });
      return scope;
    });
    throw e;
  }
};

/** iterates paginated results for ad account items for a user until exhausted */
export const getAllAdAccounts = async ({
  userId = 'me',
}: {
  userId: string;
}): Promise<GetAdAccountsResponse['data']> => {
  let adAccountResponse = await getAdAccounts({ userId });
  let adAccounts = adAccountResponse.data ?? [];

  while (adAccountResponse.paging?.cursors.after) {
    adAccountResponse = await getAdAccounts({
      userId,
      after: adAccountResponse.paging?.cursors.after,
    });

    adAccounts = [...adAccounts, ...(adAccountResponse.data ?? [])];
  }

  return adAccounts;
};

// this response is non-typical, no data or paging fields
export type GetUsersForAdAccountResponse = Pick<AdAccount, 'id' | 'users'>;

/** gets the users of an ad account (separate call to getting an ad account due to varying permissions for the fields) */
export const getAdAccountUsers = ({
  adAccountId,
}: {
  adAccountId: string;
}): Promise<GetUsersForAdAccountResponse> => {
  const params: URLSearchParams = new URLSearchParams();
  params.append('fields', 'id,users{id,name,tasks}');

  try {
    const promise = new Promise<GetUsersForAdAccountResponse>((resolve) => {
      FB.api(
        `/${adAccountId}?${params.toString()}`,
        async (response: GetUsersForAdAccountResponse) => {
          resolve(response);
        }
      );
    });
    return promise;
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('facebook/{adAccountId}');
      scope.setExtras({ adAccountId, params: params.toString() });
      return scope;
    });
    throw e;
  }
};

type Business = {
  id: string;
  name: string;
  profile_picture_uri?: string;
};

type BusinessUser = {
  id: string;
  first_name: string;
  email: string;
  role: string;
  business: Business;
};

export type GetBusinessUsersResponse = FBResponse<BusinessUser[]>;

/** gets the 'business user' of a user for the businesses they have access to (user <> business user <> business) */
const getBusinessUsers = ({
  userId = 'me',
  limit = 25,
  after = undefined,
}: {
  userId: string;
  limit?: number;
  after?: string;
}): Promise<GetBusinessUsersResponse> => {
  const params: URLSearchParams = new URLSearchParams();
  params.append(
    'fields',
    'id,email,name,role,business{id,name,profile_picture_uri}'
  );
  params.append('limit', `${limit}`);

  if (after) {
    params.append('after', `${after}`);
  }

  try {
    const promise = new Promise<GetBusinessUsersResponse>((resolve, reject) => {
      FB.api(
        `/${userId}/business_users?${params.toString()}`,
        async (response: GetBusinessUsersResponse) => {
          if (response.error) {
            reject(
              new Error(
                response.error.message ?? 'Error getting business users'
              )
            );
            return;
          }

          if (response.data == null) {
            reject(new Error('No data received for business users'));
            return;
          }

          resolve(response);
        }
      );
    });

    return promise;
  } catch (e) {
    captureException(e, (scope) => {
      scope.setTransactionName('facebook/{userId}/business_users');
      scope.setExtras({ userId, params: params.toString() });
      return scope;
    });
    throw e;
  }
};

/** iterates paginated results for business user items for a user until exhausted */
export const getAllBusinessUsers = async ({
  userId = 'me',
}: {
  userId: string;
}): Promise<BusinessUser[]> => {
  let businessUsersResponse = await getBusinessUsers({ userId });
  let businessUsers: BusinessUser[] = businessUsersResponse?.data ?? [];

  while (businessUsersResponse?.paging?.cursors.after) {
    businessUsersResponse = await getBusinessUsers({
      userId,
      after: businessUsersResponse.paging?.cursors.after,
    });

    businessUsers = [...businessUsers, ...(businessUsersResponse.data ?? [])];
  }

  return businessUsers;
};
