import { createContext, useContext } from 'react';
import {
  groupSchema,
  InsightsFacebookGroup,
  metricFilterSchema,
  migrateFormerColumnMetricsToActionMetric,
  stringifyInsightsFacebookActionMetric,
} from '@magicbrief/common';
import { atom, Atom, useSetAtom, WritableAtom, useAtomValue } from 'jotai';
import { isEqual } from 'lodash';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage';
import { inferProcedureOutput } from '@trpc/server';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { z } from 'zod';
import type { ActiveMetric } from '@magicbrief/server/src/insights/types';

export type InsightsSort = {
  id: string;
  desc: boolean;
};

export interface InsightsState {
  display: Array<string>;
  sort: Array<InsightsSort>;
  filter: Array<ActiveMetric>;
  group: InsightsFacebookGroup | null;
  columnSizing: Record<string, number>;
  graphMetrics: Array<string>;
  selected: Array<string | null> | 'default';
  layout: 'table' | 'grid';
}

export type InsightsView = 'overview' | 'analysis' | 'compare';

type InsightsStateAction =
  | {
      type: 'setDisplay';
      value: Array<string>;
    }
  | {
      type: 'setColumnSizing';
      value: Record<string, number>;
    }
  | { type: 'toggleDisplay'; value: string }
  | {
      type: 'setFilter';
      value: Array<ActiveMetric>;
    }
  | { type: 'addFilter'; value: ActiveMetric }
  | { type: 'removeFilter'; value: ActiveMetric }
  | { type: 'setSort'; value: Array<InsightsSort> }
  | { type: 'addSort'; value: InsightsSort }
  | { type: 'removeSort'; value: InsightsSort }
  | { type: 'toggleSort'; value: InsightsSort }
  | {
      type: 'setGroup';
      value: InsightsFacebookGroup | null;
    }
  | { type: 'addGraphMetric'; value: string }
  | { type: 'removeGraphMetric'; value: string }
  | { type: 'setGraphMetrics'; value: Array<string> }
  | { type: 'setSelected'; value: Array<string | null> | 'default' }
  | { type: 'setLayout'; value: 'table' | 'grid' };

const DEFAULT_INSIGHTS_FILTER: Array<ActiveMetric> = [];
const DEFAULT_INSIGHTS_SORT: Array<InsightsSort> = [
  { id: 'spend', desc: true },
];
const DEFAULT_INSIGHTS_DISPLAY: Array<string> = [
  'spend',
  'impressions',
  'clicks',
  'cpm',
  'cpc',
  'clickThroughRateAll', // Click through rate (links)
];
const DEFAULT_INSIGHTS_GRAPH_METRICS: Array<string> = [
  'spend',
  'impressions',
  'clicks',
];

type InsightsPersistentStateContextValue = {
  all: WritableAtom<InsightsState, [InsightsStateAction], void>;
  filter: Atom<Array<ActiveMetric>>;
  display: Atom<Array<string>>;
  columnSizing: Atom<Record<string, number>>;
  sort: Atom<Array<InsightsSort>>;
  group: Atom<InsightsFacebookGroup | null>;
  graphMetrics: Atom<Array<string>>;
  selected: Atom<Array<string | null> | 'default'>;
  layout: Atom<'table' | 'grid'>;
};

export const InsightsPersistentStateContext =
  createContext<InsightsPersistentStateContextValue | null>(null);

export function createInsightsDefaultPersistentState(): InsightsState {
  return {
    filter: DEFAULT_INSIGHTS_FILTER,
    display: DEFAULT_INSIGHTS_DISPLAY,
    sort: DEFAULT_INSIGHTS_SORT,
    group: null,
    columnSizing: {},
    graphMetrics: DEFAULT_INSIGHTS_GRAPH_METRICS,
    selected: 'default',
    layout: 'table',
  };
}

function hydrateInsightsPersistentState(value: Partial<InsightsState>) {
  const display = value.display
    ? [
        ...new Set(
          value.display
            .map((x) => {
              // Capture bugged metric used in some cases
              if (x === 'link_clicks') {
                return null;
              }
              const converted = migrateFormerColumnMetricsToActionMetric(x);
              return converted
                ? stringifyInsightsFacebookActionMetric(converted)
                : x;
            })
            .filter((x): x is Exclude<typeof x, null> => !!x)
        ),
      ]
    : undefined;

  const graphMetrics = value.graphMetrics ?? value.sort?.map((x) => x.id) ?? [];

  const layout = value.layout ?? 'table';

  const sort = value.sort?.reduce<InsightsSort[]>((acc, x) => {
    // Trim before conversion
    if (acc.length >= 1) return acc;

    const converted = migrateFormerColumnMetricsToActionMetric(x.id);

    const value = {
      ...x,
      id: converted ? stringifyInsightsFacebookActionMetric(converted) : x.id,
    };

    if (!acc.some((y) => y.id === value.id)) {
      acc.push(value);
    }

    return acc;
  }, []);

  const filter = value.filter?.reduce<ActiveMetric[]>((acc, x) => {
    const converted = migrateFormerColumnMetricsToActionMetric(x.metric);

    const value = (
      converted
        ? {
            ...x,
            metricType: 'action',
            metric: stringifyInsightsFacebookActionMetric(converted),
          }
        : x
    ) as ActiveMetric;

    if (!acc.some((y) => isEqual(x, y))) {
      acc.push(value);
    }

    return acc;
  }, []);

  const migrated: Partial<InsightsState> = {
    ...createInsightsDefaultPersistentState(),
    ...value,
    display,
    sort,
    graphMetrics,
    filter,
    layout,
    columnSizing: Object.entries(value.columnSizing ?? {}).reduce<
      Record<string, number>
    >((acc, curr) => {
      const converted = migrateFormerColumnMetricsToActionMetric(curr[0]);
      if (converted) {
        acc[stringifyInsightsFacebookActionMetric(converted)] = curr[1];
      } else {
        acc[curr[0]] = curr[1];
      }
      return acc;
    }, {}),
  };

  return migrated;
}

function reducer(
  state: InsightsState,
  { value, type }: InsightsStateAction
): InsightsState {
  switch (type) {
    case 'setDisplay':
      return {
        ...state,
        display: value,
      };
    case 'setSort':
      return {
        ...state,
        sort: value,
      };
    case 'setFilter':
      return {
        ...state,
        filter: value,
      };
    case 'setGroup':
      return {
        ...state,
        group: value,
        selected: value !== state.group ? 'default' : state.selected,
      };
    case 'toggleDisplay': {
      const oldValue = state.display;
      const idx = oldValue?.findIndex((x) => x === value);
      return {
        ...state,
        display:
          idx === -1
            ? [...oldValue, value]
            : [...oldValue.slice(0, idx), ...oldValue.slice(idx + 1)],
      };
    }
    case 'addFilter': {
      const currentFilters = state.filter;

      // Find the index of the filter that deeply equals the incoming filter
      const matchingDeeplyEqualIndex = currentFilters.findIndex((filter) =>
        isEqual(filter, value)
      );

      // Do not mutate any filter state if the user adds the exact same filter
      if (matchingDeeplyEqualIndex !== -1) {
        return state;
      }

      return {
        ...state,
        filter: [...currentFilters, value],
      };
    }
    case 'removeFilter': {
      const currentFilters = state.filter;

      const newFilters = currentFilters.filter(
        (filter) => !isEqual(filter, value)
      );

      return {
        ...state,
        filter: newFilters,
      };
    }
    case 'addSort': {
      return {
        ...state,
        sort: [...state.sort, value],
      };
    }
    case 'removeSort': {
      const currentSort = state.sort;
      const newSort = currentSort.filter((sort) => sort.id !== value.id);

      return {
        ...state,
        sort: newSort,
      };
    }
    case 'toggleSort': {
      const oldValue = state.sort;
      const idx = oldValue?.findIndex((x) => x.id === value.id);
      return {
        ...state,
        sort:
          idx === -1
            ? [...oldValue, value]
            : [...oldValue.slice(0, idx), ...oldValue.slice(idx + 1)],
      };
    }
    case 'setColumnSizing': {
      return {
        ...state,
        columnSizing: value,
      };
    }

    case 'addGraphMetric': {
      return {
        ...state,
        graphMetrics: [...state.graphMetrics, value],
      };
    }
    case 'removeGraphMetric': {
      const updatedGraphMetrics = state.graphMetrics.filter(
        (metric) => metric !== value
      );

      return {
        ...state,
        graphMetrics: updatedGraphMetrics,
      };
    }
    case 'setGraphMetrics': {
      return {
        ...state,
        graphMetrics: value,
      };
    }
    case 'setSelected': {
      return {
        ...state,
        selected: value,
      };
    }

    case 'setLayout': {
      return {
        ...state,
        layout: value,
      };
    }
  }
}

/**
 * Insights Hydration Validation Schemas
 */
const storedInsightsReportSchema = z.object({
  columnSizing: z.record(z.string(), z.number()).optional(),
  selected: z.array(z.string().optional()).or(z.literal('default')),
  layout: z.union([z.literal('table'), z.literal('grid')]).optional(),
});
const storedInsightsSchema = storedInsightsReportSchema.extend({
  filter: z.array(metricFilterSchema.optional()),
  group: groupSchema,
});

export function createInsightsReportStorage(
  mergeValue: InsightsState
): SyncStorage<InsightsState> {
  const storage = createJSONStorage<InsightsState>(() => localStorage);
  const getItem = (key: string, initialValue: InsightsState) => {
    try {
      const value = storage.getItem(
        key,
        initialValue
      ) as Partial<InsightsState>;

      if (!storedInsightsReportSchema.safeParse(value).success) {
        return mergeValue;
      }

      return hydrateInsightsPersistentState({
        ...value,
        ...mergeValue, // Incoming server report state takes priority
      }) as InsightsState;
    } catch (e) {
      // Error occurred, reset the UI state to report default
      const defaultState = mergeValue;
      storage.setItem(key, mergeValue);
      return defaultState;
    }
  };
  const setItem = (key: string, value: InsightsState) => {
    const { columnSizing, selected, layout } = value;
    return storage.setItem(key, {
      columnSizing,
      selected,
      layout,
    } as InsightsState);
  };
  return { ...storage, getItem, setItem };
}

export function createInsightsStandardStorage(): SyncStorage<InsightsState> {
  const storage = createJSONStorage<InsightsState>(() => localStorage);
  const getItem = (key: string, initialValue: InsightsState) => {
    try {
      const value = storage.getItem(
        key,
        initialValue
      ) as Partial<InsightsState>;

      if (!storedInsightsSchema.safeParse(value).success) {
        return createInsightsDefaultPersistentState();
      }

      return hydrateInsightsPersistentState(value) as InsightsState;
    } catch (e) {
      // Error occurred, reset the UI state to default
      const defaultState = createInsightsDefaultPersistentState();
      storage.setItem(key, defaultState);
      return defaultState;
    }
  };
  const setItem = (key: string, value: InsightsState) => {
    const {
      columnSizing,
      display,
      sort,
      filter,
      graphMetrics,
      group,
      selected,
      layout,
    } = value;
    return storage.setItem(key, {
      display,
      sort,
      filter,
      columnSizing,
      graphMetrics,
      group,
      selected,
      layout,
    } as InsightsState);
  };
  return { ...storage, getItem, setItem };
}

export function createInsightsAtom({
  accountUuid,
  reportUuid,
  view,
  type,
  initialState,
}: {
  type: 'standard' | 'report';
  accountUuid?: string;
  reportUuid?: string;
  view: InsightsView | null;
  initialState:
    | inferProcedureOutput<
        AppRouter['insightsReports']['getInsightsReport']
      >['config']
    | null;
}): WritableAtom<InsightsState, [InsightsStateAction], void> {
  const reportInitialState: InsightsState = {
    filter: initialState?.filter.metric ?? [],
    display: initialState?.filter.display ?? [],
    sort: initialState?.filter?.sort ?? [],
    group: initialState?.group ?? null,
    columnSizing: {},
    graphMetrics:
      initialState?.filter?.graphMetrics ??
      initialState?.filter?.sort?.map((x) => x.id as string) ??
      [],
    selected: 'default',
    layout: initialState?.filter?.layout ?? 'table',
  };

  const storageAtom =
    type === 'report'
      ? atomWithStorage<InsightsState>(
          `insights_report_${reportUuid ?? ''}`,
          reportInitialState,
          createInsightsReportStorage(reportInitialState),
          { getOnInit: true }
        )
      : atomWithStorage<InsightsState>(
          `insights_account_${accountUuid ?? ''}_${view}`,
          createInsightsDefaultPersistentState(),
          createInsightsStandardStorage(),
          { getOnInit: true }
        );

  const storageAtomWithReducer = atom<
    InsightsState,
    [InsightsStateAction],
    void
  >(
    (get) => get(storageAtom),
    (get, set, action) => {
      set(storageAtom, reducer(get(storageAtom), action));
    }
  );

  return storageAtomWithReducer;
}

export function useInsightsFilter() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.filter);
  return value;
}

export function useInsightsSort() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.sort);
  return value;
}

export function useInsightsDisplay() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.display);
  return value;
}

export function useInsightsGroup() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.group);
  return value;
}

export function useInsightsColumnSize() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.columnSizing);
  return value;
}

export function useInsightsGraphMetrics() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.graphMetrics);
  return value;
}

export function useInsightsSelectedItems() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.selected);
  return value;
}

export function useInsightsLayout() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.layout);
  return value;
}

export function useInsightsStoreDispatch() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const dispatch = useSetAtom(ctx.all);
  return dispatch;
}
