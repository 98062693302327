import { FC, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrayElement } from '@magicbrief/common';
import {
  OwnerType,
  PermissibleEntityType,
  PermissionRole,
} from '@magicbrief/prisma/generated/client2';
import { isRoleAllowed } from '@magicbrief/server/src/permissions/utils/is-role-allowed';
import { captureException } from '@sentry/react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as z from 'zod';
import classNames from 'classnames';
import Mail01 from 'src/assets/svgicons/duocolor/mail-01.svg';
import Trash01 from 'src/assets/svgicons/duocolor/trash-01.svg';
import UserPlus01 from 'src/assets/svgicons/duocolor/user-plus-01.svg';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import { segment } from 'src/lib/segment';
import { GetPermissionsForEntityResponse } from 'src/types/permissions';
import { trpc } from '../../lib/trpc';
import { allRoles } from '../../pages/Settings/const';
import { useUserAndOrganisation } from '../../utils/useUserAndOrganisation';
import AriaModal from '../AriaModal/AriaModal';
import { AriaButton } from '../Button/Button';
import Input from '../Input';
import Avatar from '../Misc/Avatar';
import OrganisationAvatar from '../OrganisationAvatar';
import { Select, SelectOption } from '../Select';
import { SquareLoaders } from '../SquareLoaders';

type Props = {
  entityType: PermissibleEntityType;
  entityUuid: string;
  availableRoles?: PermissionRole[];
  inviteViaEmailDefaultRole?: PermissionRole;
  showRoles?: boolean;
  showActions?: boolean;
};

const PermissionManager: FC<Props> = ({
  entityType,
  entityUuid,
  availableRoles,
  inviteViaEmailDefaultRole,
  showRoles = true,
  showActions = true,
}: Props) => {
  const { LL } = useI18nContext();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [activePermissionOwnerUuid, setActivePermissionOwnerUuid] = useState<
    string | null
  >(null);
  const [activePermissionOwnerType, setActivePermissionOwnerType] =
    useState<OwnerType | null>(null);

  const validator = useMemo(
    () =>
      z.object({
        email: z.string().email(),
      }),
    []
  );

  type FormData = z.infer<typeof validator>;

  const { register, handleSubmit, formState, setValue } = useForm<FormData>({
    resolver: zodResolver(validator),
    reValidateMode: 'onChange',
  });

  const user = useUserAndOrganisation();
  const getPermissionsForEntityQuery =
    trpc.permissions.getPermissionsForEntity.useQuery({
      entityType,
      entityUuid,
    });

  const inviteUser = trpc.permissions.inviteUser.useMutation({
    onSuccess: () => {
      void getPermissionsForEntityQuery.refetch();
      toast('Successfully invited the user', {
        className: 'toast-success',
      });
      if (entityType === 'Brief') {
        void segment?.track('shared_brief_email', {
          emailsCount: 1,
          entityType,
          entityUuid,
        });
      } else {
        void segment?.track('invited_email', {
          emailsCount: 1,
          entityType,
          entityUuid,
        });
      }

      setValue('email', '');
    },
    onError: (error) => {
      void getPermissionsForEntityQuery.refetch();
      captureException(error);
      toast(error.message ?? 'Error while inviting the user', {
        className: 'toast-danger',
      });
    },
  });

  const upsertPermission = trpc.permissions.upsertPermission.useMutation({
    onSuccess: () => {
      void getPermissionsForEntityQuery.refetch();
      toast('Successfully updated the users role', {
        className: 'toast-success',
      });
    },
    onError: (error) => {
      void getPermissionsForEntityQuery.refetch();
      captureException(error);
      toast(error.message ?? 'Error while updating the users role', {
        className: 'toast-danger',
      });
    },
  });

  const deletePermission = trpc.permissions.deletePermission.useMutation({
    onSuccess: () => {
      setShowConfirmDeleteModal(false);
      void getPermissionsForEntityQuery.refetch();
      toast('Successfully removed the user', {
        className: 'toast-success',
      });
      void segment?.track('removed_seat');
    },
    onError: (error) => {
      void getPermissionsForEntityQuery.refetch();
      captureException(error);
      toast(error.message ?? 'Error while removing the user', {
        className: 'toast-danger',
      });
    },
  });

  const onInvite = handleSubmit(({ email }) => {
    inviteUser.mutate({
      email,
      entityType,
      entityUuid,
      role: inviteViaEmailDefaultRole,
    });
  });

  const onUpdate = (
    ownerType: OwnerType,
    ownerUuid: string,
    newRole: PermissionRole
  ) => {
    upsertPermission.mutate({
      ownerType,
      ownerUuid,
      entityType,
      entityUuid,
      role: newRole,
    });
  };

  const isLoading = user.isLoading || getPermissionsForEntityQuery.isLoading;
  const isWorking =
    inviteUser.isLoading ||
    upsertPermission.isLoading ||
    deletePermission.isLoading;

  const currentUser = user.data?.user;

  /**
   * Direct permissions for owner <> entity e.g., user <> entity, org <> entity
   *
   * These are shown in the UI and are also used to determine action controls
   */
  const directPermissions = getPermissionsForEntityQuery.data?.direct;
  const currentUserDirectPermission = directPermissions?.find(
    (permission) =>
      currentUser != null && permission.ownerUuid === currentUser.uuid
  );

  /**
   * Indirect permissions for owner <> entity e.g., user <> org <> entity
   *
   * These are not shown in UI and are only used to determine action controls where a direct permission is not available
   */
  const indirectPermissions = getPermissionsForEntityQuery.data?.indirect;
  const currentUserIndirectPermission = indirectPermissions?.find(
    (permission) =>
      currentUser != null && permission.ownerUuid === currentUser.uuid
  );

  const assignableRoles = (availableRoles ?? allRoles).filter(
    (role) => !['wizard', 'superuser', 'unset', 'owner'].includes(role)
  );

  /**
   * These are used for controlling what we show to users in permissions list & dropdowns
   *
   * Rules:
   * i. we hide wizards from everyone
   * ii. we hide superusers from everyone but wizards
   * */
  const filteredRoles = () => {
    const currentRole =
      currentUserDirectPermission?.role || currentUserIndirectPermission?.role;

    if (currentRole == null) {
      return [];
    }

    let hiddenRoles: PermissionRole[] = [];
    if (currentRole === 'wizard') {
      hiddenRoles = ['unset'];
    } else if (currentRole === 'superuser') {
      hiddenRoles = ['wizard', 'unset'];
    } else {
      return assignableRoles;
    }

    return allRoles.filter((role) => !hiddenRoles.includes(role));
  };

  const filteredDirectPermissions = directPermissions?.filter((permission) => {
    return [...filteredRoles(), 'owner'].includes(permission.role);
  });

  const canUserMutatePermission = (
    ownerType: OwnerType,
    ownerUuid: string,
    role: PermissionRole
  ) => {
    if (
      currentUser == null ||
      (currentUserDirectPermission == null &&
        currentUserIndirectPermission == null)
    ) {
      return false;
    }

    if (currentUserDirectPermission != null) {
      if (currentUser.uuid === ownerUuid) {
        return false;
      }

      return isRoleAllowed(currentUserDirectPermission.role, role, '>');
    }

    if (currentUserIndirectPermission != null) {
      if (
        ownerType === 'Organisation' &&
        ownerUuid === currentUserIndirectPermission.entityUuid
      ) {
        return false;
      }

      return isRoleAllowed(currentUserIndirectPermission.role, role, '>');
    }
  };

  const getOptions = (
    permission: ArrayElement<
      | GetPermissionsForEntityResponse['direct']
      | GetPermissionsForEntityResponse['indirect']
    >
  ) => {
    return filteredRoles()
      .filter(
        (role) =>
          permission.ownerType === 'User' ||
          // hide privileged roles for organisation permissions to eliminate possibility of accidental assignment
          (permission.ownerType === 'Organisation' &&
            assignableRoles.includes(role))
      )
      .reduce<SelectOption<PermissionRole>[]>(
        (acc, role) => [
          ...acc,
          {
            id: role,
            label: role,
            value: role,
            disabled: !canUserMutatePermission(
              permission.ownerType,
              permission.ownerUuid,
              permission.role
            ),
          },
        ],
        []
      );
  };

  return (
    <>
      <div className="flex w-full flex-col gap-2">
        <div>
          <form id="invite-user-form" onSubmit={onInvite}>
            <label
              htmlFor="invite-email-input"
              className="block pb-1 text-xs text-primary sm:text-sm"
            >
              {LL.labels.invite()}
            </label>
            <div className="flex w-full flex-col gap-4 pb-4 sm:flex-row">
              <div className="grow">
                <Input
                  label=""
                  id="invite-email-input"
                  type="email"
                  autoCorrect="off" // prevent Safari attempting to correct emails...
                  placeholder="merlin@magicbrief.com"
                  className="w-full"
                  disabled={isWorking}
                  {...register('email', {
                    required: {
                      value: true,
                      message: LL.errors.fieldRequired(),
                    },
                  })}
                  error={formState.errors.email?.message}
                >
                  <span className="text-primary">
                    <Icon>
                      <Mail01 />
                    </Icon>
                  </span>
                </Input>
              </div>
              <div className="grow sm:grow-0">
                <AriaButton
                  htmlType="submit"
                  className="w-full sm:w-auto"
                  icon={
                    <Icon>
                      <UserPlus01 />
                    </Icon>
                  }
                  isDisabled={!formState.isValid}
                  loading={inviteUser.isLoading}
                >
                  Add
                </AriaButton>
              </div>
            </div>
          </form>
        </div>
        <div className="flex max-h-[290px] w-full flex-col gap-2 overflow-auto pb-2">
          {isLoading ? (
            <SquareLoaders className="w-full" amount={3} />
          ) : (
            user.data != null &&
            filteredDirectPermissions?.map((permission) => (
              <div
                key={permission.uuid}
                className={classNames(
                  'flex w-full flex-col items-start gap-2 overflow-visible rounded-lg border border-solid border-purple-200 p-2 shadow-sm sm:flex-row',
                  permission.role === 'wizard' ? 'bg-purple-100' : 'bg-white'
                )}
              >
                <div className="flex shrink grow items-center overflow-hidden">
                  {permission.ownerType === 'User' && (
                    <Avatar
                      src={permission.metadata?.profilePicURL ?? undefined}
                      className="ml-1.5 h-6 w-6 shrink-0"
                      initial={permission.metadata?.name?.charAt(0)}
                    />
                  )}

                  {permission.ownerType === 'Organisation' && (
                    <OrganisationAvatar
                      profilePicURL={permission.metadata?.profilePicURL}
                      className="ml-1.5 !h-6 !w-6 shrink-0"
                    />
                  )}

                  <div className="overflow-hidden text-ellipsis">
                    {permission.ownerType === 'User' && (
                      <div className="px-3.5 text-primary">
                        <p className="line-clamp-1 text-sm font-semibold">
                          {permission.metadata.name}
                        </p>
                        <p className="truncate text-xs font-normal text-purple-500">
                          {permission.metadata.email}
                        </p>
                        {permission.status === 'pending' && (
                          <span className="w-max truncate rounded bg-purple-600 px-1.5 py-0.5 text-xxs font-normal text-white">
                            Pending
                          </span>
                        )}
                      </div>
                    )}
                    {permission.ownerType === 'Organisation' && (
                      <div className="px-3.5 text-primary">
                        <p className="line-clamp-1 text-sm font-semibold">
                          {permission.metadata.name}
                        </p>
                        <p className="truncate text-xs font-normal text-purple-400">
                          Organisation
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex w-full gap-2 sm:w-fit">
                  {showRoles && (
                    <div className="w-1/2 sm:min-w-[150px]">
                      <Select
                        renderOptionLabel={(x) => x.label}
                        className="capitalize"
                        options={getOptions(permission)}
                        by="value"
                        value={{
                          id: permission.role,
                          label: permission.role,
                          value: permission.role,
                          disabled: !canUserMutatePermission(
                            permission.ownerType,
                            permission.ownerUuid,
                            permission.role
                          ),
                        }}
                        onChange={(option) => {
                          onUpdate(
                            permission.ownerType,
                            permission.ownerUuid,
                            option.value
                          );
                        }}
                        disabled={
                          isWorking ||
                          !canUserMutatePermission(
                            permission.ownerType,
                            permission.ownerUuid,
                            permission.role
                          )
                        }
                      />
                    </div>
                  )}

                  {showActions && (
                    <div className="col-span-2 flex grow-0 justify-center">
                      <AriaButton
                        variant="text"
                        colour="danger"
                        onPress={() => {
                          setActivePermissionOwnerUuid(permission.ownerUuid);
                          setActivePermissionOwnerType(permission.ownerType);
                          setShowConfirmDeleteModal(true);
                        }}
                        icon={
                          <Icon>
                            <Trash01 />
                          </Icon>
                        }
                        isDisabled={
                          isWorking ||
                          !canUserMutatePermission(
                            permission.ownerType,
                            permission.ownerUuid,
                            permission.role
                          )
                        }
                      />
                    </div>
                  )}
                </div>

                <AriaModal
                  show={showConfirmDeleteModal}
                  onClose={() => setShowConfirmDeleteModal(false)}
                  title={LL.permissions.delete.modal.title()}
                  footer={
                    <AriaButton
                      loading={deletePermission.isLoading}
                      variant="primary"
                      colour="danger"
                      onPress={() => {
                        deletePermission.mutate({
                          ownerType: activePermissionOwnerType!,
                          ownerUuid: activePermissionOwnerUuid!,
                          entityType,
                          entityUuid,
                        });
                      }}
                      className="mb-2 w-full"
                    >
                      {LL.confirm()}
                    </AriaButton>
                  }
                >
                  <div className="text-primary">
                    {LL.permissions.delete.modal.body()}
                  </div>
                </AriaModal>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default PermissionManager;
