import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert } from 'src/components/Alert';
import { SimpleModal } from 'src/components/Modal';
import { useI18nContext } from 'src/i18n/i18n-react';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { AriaButton } from '../../../components/Button/Button';
import { trpc } from '../../../lib/trpc';

type Props = {
  reportUuid: string;
  reportName: string;
  show: boolean;
  onClose: () => void;
};

const DeleteReportModal: FC<Props> = ({
  reportUuid,
  reportName,
  show,
  onClose,
}) => {
  const navigate = useNavigate();
  const trpcUtils = trpc.useUtils();
  const { LL } = useI18nContext();
  const { recordEvent } = useNewAnalyticsEvent();

  const deleteInsightsReport =
    trpc.insightsReports.deleteInsightsReport.useMutation({
      onSuccess: (data) => {
        onClose();
        navigate(`/insights/accounts/${data.insightsAdAccountFacebookUuid}`);
        void trpcUtils.insightsReports.getManyInsightsReports.invalidate();
        toast.success(LL.insights.reports.deleteModal.success(), {
          className: 'toast-success',
        });
        void recordEvent({
          action: 'Deleted',
          target: 'Insights Report',
          metadata: {
            reportName,
            uuid: reportUuid,
          },
        });
      },
    });

  return (
    <SimpleModal
      title={LL.insights.reports.deleteModal.title({ name: reportName })}
      show={show}
      onClose={deleteInsightsReport.isLoading ? null : onClose}
    >
      <div className="flex flex-col gap-3">
        {deleteInsightsReport.error && (
          <Alert type="error">
            {LL.errors.genericWithDetail({
              detail: deleteInsightsReport.error.message,
            })}
          </Alert>
        )}

        <div className="flex flex-col gap-2">
          <p className="text-sm text-primary">
            {LL.insights.reports.deleteModal.detail()}
          </p>
        </div>
        <AriaButton
          variant="primary"
          colour="danger"
          loading={deleteInsightsReport.isLoading}
          className="w-full"
          onPress={() => deleteInsightsReport.mutate({ uuid: reportUuid })}
        >
          {LL.insights.reports.deleteModal.submitBtn()}
        </AriaButton>
      </div>
    </SimpleModal>
  );
};

export default DeleteReportModal;
