import { useCallback, useEffect } from 'react';
import {
  GetManyFacebookAdsWithInsightsV2Input,
  GetStatisticsForFacebookAdsV2Input,
} from '@magicbrief/server/src/insights/insights.trpc';
import {
  ActiveMetric,
  ForTimePeriodInput,
} from '@magicbrief/server/src/insights/types';
import { captureException } from '@sentry/react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { InsightsFacebookGroup } from '@magicbrief/common';
import dayjs from 'src/lib/dayjs';
import { useI18nContext } from '../../../i18n/i18n-react';
import { trpc } from '../../../lib/trpc';
import {
  GetFacebookAdWithInsightsResponse,
  GetManyFacebookAdsWithInsightsResponse,
} from '../../../types/insights';
import { useInsightsSearchParams } from './useInsightsSearchParams';
import {
  InsightsSort,
  useInsightsFilter,
  useInsightsGroup,
  useInsightsSelectedItems,
  useInsightsSort,
  useInsightsStoreDispatch,
} from './useInsightsPersistentState';
import { useInsightsJob } from './useInsightsJob';
import { INSIGHTS_PAGE_TAKE_LIMIT } from './constants';

const FIVE_SECONDS = 5 * 1000;

type UseInsightsAdsProps =
  | {
      type: 'account';
      uuid: string | undefined;
      jobStatus: 'processing' | 'failed' | 'completed' | undefined;
    }
  | {
      type: 'report';
      uuid: string | undefined;
    };

export function useInsightsAds(params: UseInsightsAdsProps) {
  const { getParsedValues } = useInsightsSearchParams();
  const metric = useInsightsFilter();
  const sort = useInsightsSort();
  const group = useInsightsGroup();
  const { forTimePeriod, attributionWindow } = getParsedValues();

  return useInsightsAdsQuery({
    sort,
    metric,
    group,
    forTimePeriod,
    attributionWindow,
    ...params,
  });
}

type UseInsightsAdsQueryProps = UseInsightsAdsProps & {
  sort: Array<InsightsSort>;
  metric: ActiveMetric[];
  group: InsightsFacebookGroup | null;
  forTimePeriod: ForTimePeriodInput;
  attributionWindow: 'default' | 'custom' | null;
};

export function useInsightsAdsQuery({
  sort,
  metric,
  group,
  forTimePeriod,
  attributionWindow,
  ...rest
}: UseInsightsAdsQueryProps) {
  const { LL } = useI18nContext();
  const selected = useInsightsSelectedItems();
  const dispatch = useInsightsStoreDispatch();

  function getNextPageParam(
    lastPage: GetManyFacebookAdsWithInsightsResponse,
    pages: unknown[][]
  ) {
    if (pages.length === 0) {
      return undefined;
    }

    if (lastPage.length < INSIGHTS_PAGE_TAKE_LIMIT) {
      return undefined;
    }

    return (pages.length - 1) * INSIGHTS_PAGE_TAKE_LIMIT + lastPage.length;
  }

  const filter = {
    metric,
    forTimePeriod,
  };

  const params: GetManyFacebookAdsWithInsightsV2Input =
    rest.type === 'account'
      ? {
          type: 'account',
          insightsAdAccountFacebookUuid: rest.uuid || '',
          count: INSIGHTS_PAGE_TAKE_LIMIT,
          group,
          attributionWindow: attributionWindow ?? 'default',
          sort,
          filter,
        }
      : {
          type: 'report',
          reportUuid: rest.uuid || '',
          count: INSIGHTS_PAGE_TAKE_LIMIT,
        };

  const ads = trpc.insights.getManyFacebookAdsWithInsightsV2.useInfiniteQuery(
    params,
    {
      enabled: !!rest.uuid,
      getNextPageParam,
      keepPreviousData: true,
      onError: (error) => {
        const msg = LL.errors.genericWithDetail({
          detail: error instanceof Error ? error.message : 'Unknown error',
        });
        captureException(error, (scope) => {
          scope.setTransactionName('getManyFacebookAdsWithInsights');
          return scope;
        });
        toast.error(msg, { className: 'toast-danger' });
      },
      refetchInterval() {
        if (rest.type === 'account' && rest.jobStatus === 'processing') {
          return FIVE_SECONDS;
        }
        return 0;
      },
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  );

  useEffect(() => {
    if (selected === 'default' && !ads.isFetching && ads.data) {
      dispatch({
        type: 'setSelected',
        value: ads.data.pages[0]
          ?.slice(0, 5)
          .map((x) => (x.type === 'ad' ? x.uuid : x.group)),
      });
    }
  }, [selected, ads, dispatch]);

  const onLoadMore = async () => {
    if (!ads.isLoading && !ads.isFetching && !ads.isError) {
      return ads.fetchNextPage();
    }
  };

  const isAdsEmpty =
    ads.isSuccess &&
    !ads.isFetching &&
    (ads.data.pages.length === 0 || ads.data.pages[0].length === 0);

  const getAdAtFlatIndex = useCallback(
    (index: number): { ad: GetFacebookAdWithInsightsResponse } | null => {
      const pageIndex = Math.floor(index / INSIGHTS_PAGE_TAKE_LIMIT);
      const withinPageIndex = index - pageIndex * INSIGHTS_PAGE_TAKE_LIMIT;
      if (
        ads.data &&
        index >= 0 &&
        pageIndex < ads.data.pages.length &&
        withinPageIndex < ads.data.pages[pageIndex]?.length
      ) {
        const ad = ads.data.pages[pageIndex][withinPageIndex];
        if (ad.type === 'ad') {
          return { ad } ?? null;
        }
        return null;
      }
      return null;
    },
    [ads.data]
  );

  return {
    ads,
    onLoadMore,
    isAdsEmpty,
    getAdAtFlatIndex,
  };
}

export function useInsightsFacebookAccount({
  accountUuid,
  shouldFetch = true,
}: {
  accountUuid: string | undefined;
  shouldFetch?: boolean;
}) {
  const navigate = useNavigate();
  const { reportUuid } = useParams();

  const facebookAdAccount = trpc.insights.getFacebookAdAccount.useQuery(
    { uuid: accountUuid || '' },
    {
      enabled: shouldFetch && !!accountUuid,
    }
  );

  // handles if a user goes uses the wrong Report link (not the share link)
  // or does not have permission to account
  if (facebookAdAccount.error) {
    if (reportUuid != null) {
      navigate(
        `/insights/reports/share/${reportUuid}?${window.location.search}`
      );
    } else {
      navigate(`/insights?${window.location.search}`);
    }
  }

  const accountCustomEvents =
    facebookAdAccount.data?.InsightsAdAccountCustomEvent.map(
      (customMetric) => customMetric.name
    );

  const currency = facebookAdAccount.data?.currency;

  return {
    facebookAdAccount,
    currency,
    accountCustomEvents,
    accountCustomConversions:
      facebookAdAccount.data?.InsightsAdAccountCustomConversion,
  };
}

export function useInsightsAccountLevelStatistics(params: UseInsightsAdsProps) {
  const { getParsedValues } = useInsightsSearchParams();
  const { forTimePeriod, attributionWindow } = getParsedValues();
  const metric = useInsightsFilter();
  const group = useInsightsGroup();

  return useInsightsStatisticsQuery({
    metric,
    forTimePeriod,
    group,
    attributionWindow,
    ...params,
  });
}

export function useInsightsStatisticsQuery(
  params: UseInsightsAdsProps & Omit<UseInsightsAdsQueryProps, 'sort'>
) {
  const forTimePeriod = params.forTimePeriod;
  const filter = { forTimePeriod, metric: params.metric };
  const input: GetStatisticsForFacebookAdsV2Input =
    params.type === 'report'
      ? {
          type: 'report',
          reportUuid: params.uuid || '',
        }
      : {
          type: 'account',
          insightsAdAccountFacebookUuid: params.uuid || '',
          filter,
          attributionWindow: params.attributionWindow,
          group: params.group,
        };

  const statistics = trpc.insights.getStatisticsForFacebookAdsV2.useQuery(
    input,
    {
      enabled: !!params.uuid,
      refetchInterval() {
        if (params.type === 'account' && params.jobStatus === 'processing') {
          return FIVE_SECONDS;
        }
        return 0;
      },
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  );

  return {
    statistics,
  };
}

export function useRefetchData() {
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();
  const { accountUuid } = useParams();
  const { getParsedValues } = useInsightsSearchParams();
  const { forTimePeriod } = getParsedValues();

  const { facebookAdAccount } = useInsightsFacebookAccount({
    accountUuid,
  });

  const job = useInsightsJob();

  const isFacebookAccountDisabled =
    facebookAdAccount.data?.InsightsOrganisationAdAccountFacebook?.Integration
      ?.config?.status === 'disabled';

  const fifteenMinutesAgo = dayjs().subtract(15, 'minute');

  const isDisabled =
    isFacebookAccountDisabled ||
    facebookAdAccount.isFetching ||
    facebookAdAccount.data == null ||
    (job && job.asOfTime.valueOf() - fifteenMinutesAgo.valueOf() > 0);

  const refetchFacebookData = trpc.insights.refetchFacebookData.useMutation({
    async onSuccess(jobData) {
      if (accountUuid) {
        trpcUtils.insights.getAccountSyncStatus.setData(
          { adAccountUuid: accountUuid },
          jobData
        );
      }
    },
    async onMutate({ adAccountUuid }) {
      await trpcUtils.insights.getAccountSyncStatus.cancel({
        adAccountUuid: adAccountUuid,
      });
    },
    onError: async (error) => {
      const msg = LL.errors.genericWithDetail({
        detail: error instanceof Error ? error.message : 'Unknown error',
      });
      captureException(error, (scope) => {
        scope.setTransactionName('getManyFacebookAdsWithInsights');
        return scope;
      });
      toast.error(msg, { className: 'toast-danger' });
    },
  });

  const refetch = (params?: {
    adAccountUuid: string;
    forTimePeriod: ForTimePeriodInput;
  }) => {
    if (facebookAdAccount.data && !isFacebookAccountDisabled) {
      refetchFacebookData.mutate({
        adAccountUuid: params?.adAccountUuid ?? facebookAdAccount.data.uuid,
        forTimePeriod: params?.forTimePeriod ?? forTimePeriod,
      });
    }
  };

  return {
    refetch,
    isDisabled,
    isLoading: refetchFacebookData.isLoading,
    isProcessing: job?.status === 'processing',
  };
}
