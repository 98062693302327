import { useState } from 'react';
import { captureException } from '@sentry/react';
import { toast } from 'react-toastify';
import { useI18nContext } from 'src/i18n/i18n-react';
import {
  getAdAccountUsers,
  getAllAdAccounts,
  getAllBusinessUsers,
  getUser,
  GetUsersForAdAccountResponse,
  login,
} from 'src/lib/facebook';

export type FacebookAuthState =
  | { status: 'init' }
  | { status: 'pending' }
  | {
      status: 'success';
      adAccounts: AdAccountResult[];
      fbUserID: string;
      accessToken: string;
    }
  | {
      status: 'error';
      error: Error;
    };

export type AdAccountResult = {
  accountId: string;
  name: string;
  profilePicURL?: string;
  role: string;
};

async function getAdAccountsForUser(userId = 'me'): Promise<AdAccountResult[]> {
  const [user, adAccounts, businessUsers] = await Promise.allSettled([
    getUser({ userId }),
    getAllAdAccounts({ userId }),
    getAllBusinessUsers({ userId }),
  ]);

  let usersForAdAccounts: PromiseSettledResult<GetUsersForAdAccountResponse>[] =
    [];
  try {
    usersForAdAccounts =
      adAccounts.status === 'fulfilled' && adAccounts.value
        ? await Promise.allSettled(
            adAccounts.value.map((adAccount) =>
              getAdAccountUsers({ adAccountId: adAccount.id })
            )
          )
        : [];
  } catch (e) {
    // do nothing, we don't care
  }

  if (user.status === 'rejected') {
    throw new Error('Error getting Facebook user');
  }

  if (adAccounts.status === 'rejected') {
    throw new Error('Error getting Facebook ad accounts');
  }

  const result =
    adAccounts.value
      ?.map((adAccount) => {
        const users =
          usersForAdAccounts
            .filter(
              (
                usersForAdAccount
              ): usersForAdAccount is PromiseFulfilledResult<GetUsersForAdAccountResponse> =>
                usersForAdAccount.status === 'fulfilled'
            )
            .find(
              (userForAdAccount) => userForAdAccount.value.id === adAccount.id
            )?.value.users?.data ?? [];

        const businessUser =
          businessUsers.status === 'fulfilled' && businessUsers.value
            ? businessUsers.value.find(
                (businessUser) => businessUser.business.id === adAccount.owner
              )
            : null;

        return {
          id: adAccount.id,
          accountId: adAccount.id.replace('act_', ''),
          name: adAccount.name,
          profilePicURL: businessUser?.business.profile_picture_uri,
          role:
            adAccount.owner === user.value.id
              ? 'ADMIN'
              : businessUser?.role ?? 'unknown',
          users: users,
        };
      })
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }) || [];

  return result;
}

export const useFacebook = () => {
  const { LL } = useI18nContext();

  const [authState, setAuthState] = useState<FacebookAuthState>({
    status: 'init',
  });

  const authenticate = async () => {
    try {
      const { fbUserID, accessToken } = await login();

      setAuthState({
        status: 'pending',
      });

      const adAccounts = await getAdAccountsForUser();

      setAuthState({
        status: 'success',
        fbUserID,
        accessToken,
        adAccounts,
      });
    } catch (e) {
      setAuthState({ status: 'error', error: e as Error });
      captureException(e);
      toast.error(
        LL.integrations.facebook.authenticateFacebook.error({
          error:
            e instanceof Error && 'message' in e ? e.message : 'Unknown error',
        }),
        { className: 'toast-danger' }
      );
    }
  };

  return {
    authenticate,
    authState,
    setAuthState,
  };
};
